import * as React from "react";
import Seo from "../components/seo";
import Layout from "../components/layout";
import { page, pageHeader, pageBody } from "./404.module.css";

const GettingReadyPage = () => {
  return (
    <Layout>
      <Seo pageTitle="Getting ready" />
      <main className={page}>
        <h1 className={pageHeader}>準備中 ...</h1>
        <div className={pageBody}>
          <p>もうしばらくお待ちください。</p>
        </div>
      </main>
    </Layout>
  );
};

export default GettingReadyPage;
